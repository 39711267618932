import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { handle$ } from "src/modules/base/handle";
import { planUnset } from "src/modules/plan/lib";
import { useStore } from "src/services/store/StoreProvider";

import CancelPlanIcon from "../../plan/images/CancelPlanIcon";
import { CartItem } from "../schema";
import CartItemLoading from "./CartItemLoading";
import CartPlanCallToAction from "./CartPlanCallToAction";
import CartPlanCard from "./CartPlanCard";
import CartPlanSkeleton from "./CartPlanSkeleton";

const CartPlanSection: FC<{ items: CartItem[] }> = ({ items }) => {
  const { isModifying, orderDate, plan, products, emptyCart } = useStore();
  const router = useRouter();

  if (!plan) {
    return <CartPlanCallToAction />;
  }

  const missing = Math.max(0, plan.quantity - items.length);
  const orderDateFormatted = new Date(orderDate).toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  if (!products)
    return (
      <div className="p-2.5 bg-white">
        <h2 className="mt-6 mb-7 text-3xl lg:text-[1.875rem] text-center font-sharp-grotesk-medium">Your Vood Plan</h2>
        <CartItemLoading />
      </div>
    );

  const handleCancelPlan = () =>
    handle$(() => {
      if (isModifying) {
        emptyCart();
        router.push("/currentplan");
      } else {
        planUnset();
      }
    }, {});

  return (
    <div className="p-2.5 bg-white rounded-t-lg">
      <h2 className="mt-5 mb-7 text-3xl lg:text-[1.875rem] text-center font-sharp-grotesk-medium">
        Your Vood {isModifying ? <span>Order</span> : <span>Plan</span>}
      </h2>

      {isModifying && (
        <div className="-mt-5 pb-6 font-sharp-grotesk-medium text-xl text-center">for {orderDateFormatted}</div>
      )}

      {items.map((i) => (
        <CartPlanCard key={i.cartId} item={i} />
      ))}

      {plan?.quantity &&
        new Array(missing).fill(null).map((_, idx) => {
          return <CartPlanSkeleton key={idx} position={idx + items.length + 1} />;
        })}

      <div className={clsx("mt-4 w-full", "flex items-center justify-center")}>
        <button
          className={clsx(
            "py-2 px-4",
            "text-xs text-red-500 uppercase font-sharp-grotesk-medium",
            "rounded-full",
            "border border-red-500",
            "bg-white hover:bg-gray-100",
            "flex items-center justify-between gap-8",
          )}
          onClick={handleCancelPlan}
        >
          {isModifying ? <span>Stop modifying</span> : <span>Cancel plan</span>}
          <CancelPlanIcon />
        </button>
      </div>
    </div>
  );
};

export default observer(CartPlanSection);
