import clsx from "clsx";
import React, { FC } from "react";

const QtyButton: FC<{ onClick; className }> = ({ onClick, children, className }) => {
  return (
    <button
      className={clsx(
        "flex-shrink-0",
        "h-6 sm:h-8 w-6 sm:w-8",
        "hover:bg-gray-100 rounded-full border border-black",
        "text-black",
        "flex justify-center items-center",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default QtyButton;
