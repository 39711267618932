import clsx from "clsx";
import { Field, useField, useFormikContext } from "formik";
import React, { FC } from "react";

import ErrorText from "../ErrorText";

export interface TextFieldProps {
  name: string;
  type?: string;
  as?: string;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  className?;
  append?;
}

const TextField: FC<TextFieldProps> = ({
  name,
  type = "text",
  as,
  maxLength,
  placeholder = "",
  disabled = false,
  className,
  append,
}) => {
  const [{ value, ...rest }, { error, touched }] = useField(name);
  const { isSubmitting } = useFormikContext();

  return (
    <div>
      <div className="flex items-center gap-3">
        <Field
          {...rest}
          value={value || ""}
          type={type}
          as={as}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled || isSubmitting}
          className={clsx("flex-shrink flex-grow", className)}
        />
        {append}
      </div>

      <ErrorText show={touched}>{error}</ErrorText>
    </div>
  );
};

export default TextField;
