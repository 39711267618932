import React, { FC } from "react";

const CancelPlanIcon: FC = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.75598"
        y="0.283936"
        width="12.7279"
        height="1.41421"
        transform="rotate(45 1.75598 0.283936)"
        fill="#EF4444"
      />
      <rect
        x="10.7556"
        y="1.28394"
        width="12.7279"
        height="1.41421"
        transform="rotate(135 10.7556 1.28394)"
        fill="#EF4444"
      />
    </svg>
  );
};

export default CancelPlanIcon;
