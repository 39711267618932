import clsx from "clsx";
import currency from "currency.js";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import AddButton from "src/components/button/AddButton";
import RemoveButton from "src/components/button/RemoveButton";
import Link from "src/layout/Link";
import { cartAddItem, cartRemoveProductId } from "src/modules/cart/client";
import { productGetBackgroundSrc, productGetPrice, productGetURI, productHasImage } from "src/modules/product/lib";
import { VariantType } from "src/modules/product/VariantLib";
import LoadingSection from "src/modules/system/components/LoadingSection";
import { useStore } from "src/services/store/StoreProvider";

const CartItemsMainsCard: FC<{
  productId: string;
  variant: VariantType;
  quantity: number;
}> = ({ productId, variant, quantity }) => {
  // console.log("CartItemsCard", { productId, variant });

  const store = useStore();
  const product = store.products[productId];

  if (!product) return <LoadingSection />;

  const handleAddItem = () => {
    cartAddItem(productId, variant);
  };

  const handleRemoveItem = () => {
    cartRemoveProductId(productId, variant);
  };

  const price = productGetPrice(product, variant);
  const total = currency(price).multiply(quantity);

  return (
    <div
      className={clsx(
        "min-h-20 mt-1 p-2",
        "rounded-3xl",
        "text-charcoal",
        "bg-vood",
        "flex items-center justify-between",
      )}
    >
      <div className="flex-grow p-2">
        <h3 className="font-bold font-sharp-grotesk-medium">{product.title}</h3>
        <p className="font-mono text-sm">
          {variant} {total.format()}
        </p>
      </div>

      {productHasImage(product) && (
        <Link href={productGetURI(product)} className="flex-shrink-0 w-24 h-24">
          <div
            className="w-full h-full bg-center bg-cover rounded-3xl"
            style={{
              backgroundImage: productGetBackgroundSrc(product),
            }}
          ></div>
        </Link>
      )}

      <div className={clsx("w-10 ml-2 p-1", "rounded-3xl", "bg-white", "flex flex-col items-center justify-center")}>
        <AddButton onClick={handleAddItem} className="" />
        <span className="my-1">{quantity}</span>
        <RemoveButton onClick={handleRemoveItem} className="" />
      </div>
    </div>
  );
};

export default observer(CartItemsMainsCard);
