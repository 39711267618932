import React, { FC } from "react";

const Logo: FC = () => {
  return (
    <svg width="141" height="64" viewBox="0 0 141 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4981_22258)">
        <path
          d="M29.669 0.4375L20.8383 17.9129L12.0076 0.4375H0.5V0.439937L17.4206 33.919H24.256L41.1766 0.4375H29.669Z"
          fill="#222222"
        />
        <path
          d="M123.535 0.4375H109.612V33.9165H123.535C132.889 33.9165 140.5 26.4069 140.5 17.177C140.5 7.94713 132.889 0.4375 123.535 0.4375ZM123.535 23.7559H119.91V10.6006H123.535C127.212 10.6006 130.203 13.5513 130.203 17.1794C130.2 20.8051 127.21 23.7559 123.535 23.7559Z"
          fill="#222222"
        />
        <path
          d="M50.4292 44.4692C50.044 44.2645 49.7699 43.8917 49.718 43.2948C49.6192 42.1544 50.2489 41.4722 51.7701 41.3431L52.2294 41.3041C53.7432 41.1749 54.5556 41.8864 54.6668 43.1559C54.768 44.323 54.1951 45.0321 53.1036 45.1783L53.0246 44.2792C53.2024 44.2572 53.3456 44.2061 53.4938 44.1208C53.7679 43.9648 53.9309 43.6944 53.8889 43.2095C53.8197 42.4176 53.2962 42.0984 52.2739 42.1837L51.8812 42.2178C50.8564 42.2982 50.4564 42.6954 50.5255 43.4678C50.5477 43.7163 50.6095 43.9039 50.7231 44.0453C50.9428 44.3303 51.2984 44.4327 51.6639 44.4083L51.5651 43.268L52.3159 43.2046L52.4937 45.2489L50.0094 45.4609L49.9526 44.8128L51.0688 44.652C50.8441 44.6252 50.6243 44.5642 50.4292 44.4692Z"
          fill="#222222"
        />
        <path
          d="M52.3947 46.4144L52.7725 46.2828C53.2441 46.1172 53.6565 46.066 54.0319 46.1074C54.7925 46.1976 55.3308 46.6727 55.6049 47.4354C55.7432 47.8203 55.7852 48.1761 55.721 48.5245C55.5975 49.2092 55.0839 49.7648 54.1406 50.0937L53.7627 50.2253C52.3181 50.7297 51.3674 50.2302 50.9451 49.0533C50.508 47.835 50.9303 46.9261 52.3947 46.4144ZM53.4911 49.3749L53.7998 49.2677C54.748 48.9363 55.1185 48.41 54.8641 47.6961C54.6048 46.97 53.9677 46.8165 53.0441 47.1381L52.7305 47.2477C51.8119 47.5669 51.4439 48.0738 51.7032 48.7999C51.9576 49.5138 52.5749 49.6965 53.4911 49.3749Z"
          fill="#222222"
        />
        <path
          d="M54.2568 51.196L54.5877 50.9743C55.0026 50.6989 55.3878 50.5454 55.7632 50.4943C56.5237 50.3944 57.1633 50.7209 57.6227 51.3909C57.8548 51.7296 57.9857 52.061 58.0104 52.4167C58.0647 53.1087 57.7091 53.7764 56.8793 54.327L56.5484 54.5488C55.2767 55.3943 54.2321 55.1457 53.5234 54.1102C52.7875 53.0356 52.9677 52.0512 54.2568 51.196ZM56.0694 53.7885L56.341 53.6082C57.1757 53.0527 57.4004 52.4508 56.9707 51.8246C56.5336 51.1862 55.8792 51.1936 55.0668 51.7345L54.7902 51.9172C53.9827 52.4533 53.753 53.0356 54.1901 53.674C54.6173 54.3027 55.2619 54.327 56.0694 53.7885Z"
          fill="#222222"
        />
        <path
          d="M60.3612 54.6512C60.6476 54.907 60.8526 55.1945 60.9884 55.5186C61.2502 56.1765 61.1341 56.905 60.4649 57.6385L60.1982 57.9333C59.8673 58.2963 59.5364 58.5327 59.1882 58.6472C58.4869 58.8738 57.7979 58.6886 57.2077 58.1648L55.9434 57.0439L59.1215 53.5547L60.3612 54.6512ZM57.0991 57.0488L57.6893 57.5727C58.235 58.0576 58.9215 58.0307 59.5957 57.29L59.766 57.1048C60.4402 56.3665 60.3883 55.6745 59.8278 55.1775L59.2623 54.6755L57.0991 57.0488Z"
          fill="#222222"
        />
        <path
          d="M63.4757 62.1905L62.6904 61.9127L64.3005 57.5L65.066 57.7705L65.7328 60.0219L67.7034 58.7159L68.4615 58.9863L66.8539 63.3941L66.0686 63.1164L67.2515 59.8733L67.0416 60.0438L65.3722 61.1427L65.2537 61.1013L64.6857 59.2007L64.6438 58.9839L63.4757 62.1905Z"
          fill="#222222"
        />
        <path
          d="M68.8366 61.6225L68.8662 61.2277C68.9033 60.7355 69.0193 60.3408 69.212 60.0192C69.6021 59.3686 70.259 59.0738 71.0764 59.1323C71.4888 59.1615 71.8345 59.2663 72.1358 59.4661C72.721 59.8511 73.0322 60.5406 72.9581 61.525L72.9285 61.9197C72.8149 63.428 71.9703 64.0859 70.706 63.9933C69.3947 63.8983 68.723 63.1502 68.8366 61.6225ZM72.0247 61.8223L72.0493 61.5006C72.1234 60.5089 71.7826 59.9631 71.0196 59.9071C70.2417 59.8511 69.8417 60.3627 69.77 61.3276L69.7454 61.6566C69.6737 62.6142 69.9948 63.1527 70.7726 63.2111C71.5357 63.2648 71.953 62.7799 72.0247 61.8223Z"
          fill="#222222"
        />
        <path
          d="M74.0226 61.8623L73.951 61.4725C73.8621 60.9876 73.8769 60.5758 73.9806 60.2176C74.1954 59.4915 74.756 59.0456 75.5635 58.9018C75.971 58.8312 76.3315 58.8458 76.6723 58.9676C77.3366 59.1967 77.8107 59.7888 77.986 60.761L78.0577 61.1508C78.3268 62.6396 77.6749 63.4851 76.4278 63.7044C75.1363 63.931 74.2967 63.3706 74.0226 61.8623ZM77.1612 61.2751L77.1044 60.9583C76.9266 59.9813 76.4599 59.5354 75.7043 59.6694C74.9363 59.8058 74.677 60.3979 74.8498 61.3506L74.9091 61.6747C75.082 62.6201 75.5265 63.0636 76.2945 62.9271C77.0501 62.7907 77.3316 62.2205 77.1612 61.2751Z"
          fill="#222222"
        />
        <path
          d="M79.6798 57.7511C80.033 57.5927 80.3812 57.5245 80.7343 57.5318C81.4504 57.5586 82.0801 57.9557 82.4925 58.8524L82.658 59.213C82.863 59.6565 82.9494 60.0512 82.9173 60.4118C82.8481 61.1355 82.3987 61.6862 81.6752 62.0103L80.1268 62.7047L78.1611 58.4333L79.6798 57.7511ZM80.6009 61.6594L81.322 61.3353C81.9888 61.0356 82.2407 60.4021 81.8233 59.4981L81.7196 59.2715C81.3023 58.3675 80.6405 58.1361 79.954 58.4431L79.2625 58.7525L80.6009 61.6594Z"
          fill="#222222"
        />
        <path
          d="M87.8318 57.9835L87.2218 58.5731L83.8955 55.2203L86.1452 53.0469L86.6909 53.5975L85.0512 55.1813L85.9748 56.1121L87.5182 54.6209L88.0689 55.1765L86.5255 56.6677L87.8318 57.9835Z"
          fill="#222222"
        />
        <path
          d="M88.5995 54.1635L88.2612 53.9515C87.8389 53.6859 87.5426 53.3984 87.3475 53.0792C86.9524 52.4311 87.0018 51.7196 87.4413 51.0398C87.6636 50.6962 87.9229 50.4453 88.2414 50.2796C88.8637 49.9531 89.6243 50.0115 90.4688 50.5427L90.8072 50.7547C92.0987 51.5685 92.2839 52.6163 91.6023 53.6689C90.8961 54.7605 89.9083 54.9895 88.5995 54.1635ZM90.291 51.4906L90.0169 51.3176C89.1675 50.7815 88.5205 50.8181 88.1081 51.4564C87.6883 52.1046 87.955 52.6942 88.7797 53.2157L89.0613 53.3935C89.8811 53.9101 90.5133 53.8882 90.9331 53.24C91.3455 52.6016 91.1109 52.0071 90.291 51.4906Z"
          fill="#222222"
        />
        <path
          d="M91.2813 49.781L90.901 49.6592C90.4268 49.5056 90.0663 49.301 89.7947 49.0403C89.2465 48.5115 89.1156 47.8098 89.3699 47.0422C89.4983 46.6548 89.6836 46.3478 89.9527 46.109C90.4738 45.6387 91.2245 45.5072 92.1752 45.8142L92.5555 45.936C94.0124 46.4063 94.4569 47.3712 94.0643 48.5602C93.6569 49.7907 92.758 50.2561 91.2813 49.781ZM92.2419 46.7742L91.9307 46.6743C90.9726 46.3673 90.3552 46.5622 90.1182 47.281C89.8762 48.012 90.2836 48.5188 91.2146 48.8185L91.5307 48.9209C92.4567 49.2181 93.0617 49.0403 93.3037 48.3093C93.5433 47.588 93.1679 47.0715 92.2419 46.7742Z"
          fill="#222222"
        />
        <path
          d="M90.2781 42.9116C90.3028 42.5315 90.4065 42.1953 90.5794 41.8882C90.9399 41.2791 91.5943 40.9185 92.592 40.9843L92.992 41.0111C93.4835 41.0427 93.8786 41.1548 94.1848 41.3522C94.7997 41.7542 95.0763 42.4072 95.0244 43.187L94.9108 44.8609L90.167 44.5515L90.2781 42.9116ZM94.2045 43.9594L94.2564 43.1772C94.3058 42.456 93.8588 41.937 92.8538 41.8712L92.6019 41.8541C91.5968 41.7883 91.0782 42.2562 91.0288 42.9969L90.9794 43.7449L94.2045 43.9594Z"
          fill="#222222"
        />
        <path
          d="M54.1361 0C44.5374 0 36.7266 7.70456 36.7266 17.1781C36.7266 26.6492 44.5349 34.3562 54.1361 34.3562C63.7348 34.3562 71.5457 26.6492 71.5457 17.1781C71.5432 7.70456 63.7348 0 54.1361 0ZM54.1361 24.1931C50.2146 24.1931 47.0266 21.045 47.0266 17.1781C47.0266 13.3088 50.2171 10.1631 54.1361 10.1631C58.0576 10.1631 61.2456 13.3112 61.2456 17.1781C61.2456 21.045 58.0551 24.1931 54.1361 24.1931Z"
          fill="#222222"
        />
        <path
          d="M90.6024 0C81.0037 0 73.1953 7.70456 73.1953 17.1781C73.1953 26.6492 81.0037 34.3562 90.6024 34.3562C100.201 34.3562 108.012 26.6492 108.012 17.1781C108.012 7.70456 100.201 0 90.6024 0ZM90.6024 24.1931C86.6834 24.1931 83.4929 21.045 83.4929 17.1781C83.4929 13.3088 86.6809 10.1631 90.6024 10.1631C94.5239 10.1631 97.7119 13.3112 97.7119 17.1781C97.7119 21.045 94.5239 24.1931 90.6024 24.1931Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_4981_22258">
          <rect width="140" height="64" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
