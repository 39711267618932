const GoogleAuthIcon = ({ size = 24 }) => {
  return (
    <svg
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.41421,
      }}
      version="1.1"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <rect style={{ fill: "none" }} height="24" width="24" x="0" y="0" />
      <g>
        <path
          d="M4.93,4.93c1.81,-1.81 4.31,-2.93 7.07,-2.93c2.76,0 5.26,1.12 7.07,2.93l-2.828,2.828c-1.086,-1.086 -2.586,-1.758 -4.242,-1.758c-1.656,0 -3.156,0.672 -4.242,1.758l-2.828,-2.828Z"
          style={{ fill: "#eb4134" }}
        />
        <path
          d="M16.242,16.242l2.828,2.828c-1.81,1.81 -4.31,2.93 -7.07,2.93c-2.76,0 -5.26,-1.12 -7.07,-2.93l2.828,-2.828c1.086,1.086 2.586,1.758 4.242,1.758c1.656,0 3.156,-0.672 4.242,-1.758Z"
          style={{ fill: "#34aa51" }}
        />
        <path
          d="M4.93,4.93l2.828,2.828c-1.086,1.086 -1.758,2.586 -1.758,4.242c0,1.656 0.672,3.156 1.758,4.242l-2.828,2.828c-1.81,-1.81 -2.93,-4.31 -2.93,-7.07c0,-2.76 1.12,-5.26 2.93,-7.07Z"
          style={{ fill: "#fbbe04" }}
        />
        <path
          d="M12,10l9.8,0c0.131,0.646 0.2,1.315 0.2,2c0,0.685 -0.069,1.354 -0.2,2c-0.399,1.958 -1.369,3.709 -2.73,5.07l-2.828,-2.828c0.626,-0.626 1.115,-1.39 1.416,-2.242l-5.658,0l0,-4Z"
          style={{ fill: "#4186f7" }}
        />
      </g>
    </svg>
  );
};

export default GoogleAuthIcon;
