import clsx from "clsx";
import React, { FC } from "react";

import Logo from "./Logo";

const LogoAndText: FC<{ className }> = ({ className }) => {
  return (
    <div
      className={clsx(
        className,
        "flex justify-center sm:justify-start items-center sm:items-start",
        "pt-[40px] pb-3 sm:py-0",
        "border-t sm:border-0 border-vood-dark",
      )}
    >
      <Logo />
    </div>
  );
};

export default LogoAndText;
