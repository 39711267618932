import React, { FC } from "react";

import { loginWithPopup$ } from "../client";
import GoogleAuthIcon from "../icons/GoogleAuthIcon";

const GoogleLoginButton: FC = () => {
  return (
    <button
      type="button"
      onClick={loginWithPopup$}
      className="flex justify-between items-center text-xs gap-4 pl-5 pr-6 py-4 border border-charcoal border-solid rounded-full w-48 font-sharp-grotesk-medium tracking-[0.5px] hover:bg-vood-dark"
    >
      <GoogleAuthIcon />
      GOOGLE
    </button>
  );
};

export default GoogleLoginButton;
