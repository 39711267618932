import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { useStore } from "src/services/store/StoreProvider";

const CartNoItemsMessage: FC = () => {
  const { plan } = useStore();

  return (
    <div
      className={clsx(
        "h-16 mt-6 mb-4",
        "rounded-[1.875rem] border border-black border-dashed",
        "text-charcoal font-mono text-sm",
        "bg-vood-light",
        "flex items-center justify-center",
      )}
    >
      {plan ? "Nothing extra yet" : "Your cart is empty"}
    </div>
  );
};

export default observer(CartNoItemsMessage);
