import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import Modal from "src/components/Modal";
import { useStore } from "src/services/store/StoreProvider";

import AuthForm from "./AuthForm";

const ModalLogin: FC = () => {
  const { showLogin, setShowLogin } = useStore();
  if (!showLogin) return null;
  const handleClose = () => setShowLogin(false);
  return (
    <Modal isOpen={true} onClose={handleClose}>
      <AuthForm />
    </Modal>
  );
};

export default observer(ModalLogin);
