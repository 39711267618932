import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import Link from "src/layout/Link";
import { useIsModalMenu } from "src/lib/LayoutLib";

import { useBannerMessage } from "../banner/lib";
import HeaderCartIconForMobile from "./HeaderCartIconForMobile";
import HeaderLinks from "./HeaderLinks";
import MainMenuModal from "./MainMenuModal";
import VoodLogo from "./VoodLogo";

const HeaderNav: FC = () => {
  const isModalMenu = useIsModalMenu();
  const { data: banner } = useBannerMessage();

  return (
    <>
      <nav
        className={clsx(
          "fixed left-4 xl:left-auto right-4 xl:right-auto shadow",
          banner ? "top-[84px]" : "top-6",
          "xl:w-full laptop:max-w-laptop mx-auto",
          "rounded-full",
          "bg-white",
          "text-black",
          "z-50",
          "flex justify-between items-center",
        )}
      >
        {isModalMenu && <MainMenuModal />}

        <Link href="/" className="">
          <VoodLogo
            className={clsx("h-6", "hover:text-gray-700", {
              "ml-6": !isModalMenu,
            })}
          />
        </Link>

        {isModalMenu ? (
          <HeaderCartIconForMobile /> // opens menu
        ) : (
          <div className="flex items-center">
            <HeaderLinks />
          </div>
        )}
      </nav>
    </>
  );
};

export default observer(HeaderNav);
