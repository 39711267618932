import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import Link from "src/layout/Link";

const Block: FC<{ href: string; children: ReactNode }> = ({ href, children }) => {
  return (
    <li>
      <Link href={href} className="font-mono text-xs text-charcoal/80">
        {children}
      </Link>
    </li>
  );
};

const RightLinks: FC<{ className }> = ({ className }) => {
  return (
    <div
      className={clsx(
        className,
        "text-center sm:text-left",
        "py-[40px] sm:py-0",
        "border-t sm:border-0 border-vood-dark",
      )}
    >
      <h3 className="text-xs uppercase font-sharp-grotesk-medium text-black pb-1 tracking-wide">Follow Us</h3>
      <ol>
        <Block href="https://www.instagram.com/eatvood/">Instagram</Block>
        <Block href="https://www.facebook.com/eatvood">Facebook</Block>
        <Block href="https://www.linkedin.com/company/eatvood/">LinkedIn</Block>
        <Block href="https://www.youtube.com/channel/UCa-BS5cP7OfF3ywhRM-2d7A">YouTube</Block>
      </ol>
    </div>
  );
};

export default RightLinks;
