import React, { FC } from "react";
import PhoneField, { PhoneFieldProps } from "src/components/form/PhoneField";

const AuthPhoneField: FC<PhoneFieldProps> = (props) => {
  return (
    <div className="mb-1 border-b border-black relative">
      <PhoneField {...props} className="py-3 text-black placeholder-black  bg-vood autofill-fix" />
    </div>
  );
};

export default AuthPhoneField;
