import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { validateWithPhone } from "src/lib/FormikLib";
import { formatPhone } from "src/lib/GeneralLib";
import yup from "src/lib/YupLib";
import { UserProfileSchema } from "src/modules/user/schema";
import { SetterFunction } from "src/types";
import { InferType } from "yup";

import { loginCreateWithEmail$ } from "../client";
import AuthField from "./AuthField";
import AuthPhoneField from "./AuthPhoneField";

const ThisSchema = yup
  .object({
    password: yup.string().required().ensure(),
  })
  .concat(UserProfileSchema)
  .defined();

export type ThisData = InferType<typeof ThisSchema>;

const CreateAccountForm: FC<{ setOption: SetterFunction }> = ({ setOption }) => {
  const [thinking, setThinking] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleValidation = (values) => validateWithPhone(values, ThisSchema);

  const handleSubmit = async ({ email, password, name, phone }) => {
    try {
      setThinking(true);
      phone = formatPhone(phone);
      await loginCreateWithEmail$(email, password, name, phone);
    } catch (err) {
      setError(err.message);
    } finally {
      setThinking(false);
    }
  };

  const handleReturn = () => {
    setOption("login");
  };

  return (
    <Formik validate={handleValidation} initialValues={ThisSchema.cast({})} onSubmit={handleSubmit}>
      <Form className="flex flex-col items-stretch justify-between pt-6">
        <h2 className="w-full pt-16 pb-6 text-3xl font-bold text-center text-white">Create an account</h2>

        <AuthField name="name" type="text" placeholder="Full name*" />
        <AuthField name="email" type="text" placeholder="Email*" />
        <AuthPhoneField name="phone" type="text" placeholder="Phone*" />
        <AuthField name="password" type="password" placeholder="Password*" />

        {error && <div className="mb-5 font-mono text-sm text-red-600">{error}</div>}

        <div className="flex items-center justify-center my-4">
          <button
            type="submit"
            disabled={thinking}
            className="block px-6 py-2 mb-4 text-white bg-black rounded-full hover:bg-charcoal md:mb-0 md:w-1/2"
          >
            Create Account
          </button>
        </div>

        <div className="flex items-center justify-center">
          <button
            onClick={handleReturn}
            type="button"
            disabled={thinking}
            className="px-8 py-2 font-mono text-xs font-normal"
          >
            Return to login
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default CreateAccountForm;
