import clsx from "clsx";
import React, { FC } from "react";
import Link from "src/layout/Link";

const CartPlanCallToAction: FC = () => {
  return (
    <div
      className={clsx(
        "w-full",
        "text-black",
        "bg-white bg-left bg-no-repeat",
        "rounded-t-3xl",
        "block",
        // "flex flex-col items-center justify-center"
      )}
      style={{
        backgroundImage: `url(/CartPlanCallToAction.png)`,
        backgroundSize: "250px 300px",
        backgroundPosition: "top left",
      }}
    >
      <span
        className="block w-full text-2xl text-charcoal text-center font-sharp-grotesk-medium"
        style={{
          paddingTop: 130,
        }}
      >
        Stay regular & save
        <br />
        with a Vood plan
      </span>
      <div className="flex items-center justify-center w-full pt-24 font-sharp-grotesk-medium pb-20 cart-no-items">
        <Link href="/start" query={{ stage: 1 }} className={clsx("btn btn-sized btn-red")}>
          Start a plan
        </Link>
      </div>
    </div>
  );
};

export default CartPlanCallToAction;
