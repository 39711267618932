import clsx from "clsx";
import React, { FC } from "react";
import { store } from "src/services/store/store";

const CartPlanSkeleton: FC<{ position: number }> = ({ position }) => {
  return (
    <div
      className={clsx(
        "h-16 mt-1.5",
        "rounded-[1.875rem] border border-black border-dashed",
        "text-charcoal font-mono text-sm",
        "bg-vood-light",
        "flex items-center justify-center",
      )}
    >
      Choose {store.plan.variant} meal #{position}
    </div>
  );
};

export default CartPlanSkeleton;
