import { REGIONS } from "../../config/appConfig";
import { AddressData } from "../general/address-schema";
import { ShippingZonesData } from "./schema";

export interface PostcodeProps {
  name: string;
  fringe: boolean;
  delivery: number[];
}

export interface Region {
  region: string;
  postcodes: number[];
  fringe: boolean;
  delivery: number[];
}

const getPostcodesFromRegions = (regions: Region[]) => {
  let postcodes: { [code: number]: PostcodeProps } = {};

  regions.forEach((region) =>
    region.postcodes.forEach((postcode) => {
      postcodes[postcode] = {
        name: region.region,
        fringe: region.fringe,
        delivery: region.delivery,
      };
    }),
  );

  return postcodes;
};

const getRegion = (shippingZones: ShippingZonesData[], address: AddressData) => {
  // first look to match postcodes
  const regionsWithPostcodes = shippingZones.filter((zone) => zone.postcodes.length > 0);

  for (const regionsWithPostcode of regionsWithPostcodes) {
    if (regionsWithPostcode.postcodes.includes(parseInt(address.postcode))) {
      return regionsWithPostcode;
    }
  }

  // not just match states
  const regions = shippingZones.filter((zone) => zone.postcodes.length < 1);

  for (const region of regions) {
    if (region.region == address.state) {
      return region;
    }
  }

  return null;
  //return REGIONS.find((region) => region.region === address.state);
};

const postcodes = getPostcodesFromRegions(REGIONS);

export { postcodes, getPostcodesFromRegions, getRegion };
