import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import AddButton from "src/components/button/AddButton";
import RemoveButton from "src/components/button/RemoveButton";
import Link from "src/layout/Link";
import { getId } from "src/modules/base/lib";
import { cartAddItem, cartRemoveCartId } from "src/modules/cart/client";
import { productGetBackgroundSrc, productGetURI, productHasImage } from "src/modules/product/lib";
import LoadingSection from "src/modules/system/components/LoadingSection";
import { useStore } from "src/services/store/StoreProvider";

import { CartItem } from "../schema";

const CartPlanCard: FC<{ item: CartItem }> = ({ item }) => {
  const store = useStore();
  const product = store?.products[item.productId];
  // const hasVariants = productHasVariants(product);

  if (!product) return <LoadingSection />;

  const handleAddItem = () => {
    cartAddItem(getId(product), item.variant);
  };

  const handleRemoveItem = () => {
    cartRemoveCartId(item.cartId);
  };

  return (
    <div
      className={clsx(
        "h-24 mt-1 p-2",
        "rounded-[1.875rem]",
        "text-charcoal",
        "bg-vood",
        "flex items-center justify-between",
      )}
    >
      <div className="flex-grow px-4 py-2">
        <h3 className="font-sharp-grotesk-medium leading-[1.25rem]">{product.title}</h3>
      </div>

      {productHasImage(product) && (
        <Link href={productGetURI(product)} className="w-[4.5rem] h-[4.5rem]">
          <div
            className="w-[4.5rem] h-full bg-center bg-cover rounded-3xl"
            style={{
              backgroundImage: productGetBackgroundSrc(product),
            }}
          ></div>
        </Link>
      )}

      <div className="flex flex-col w-10 gap-2 p-1 ml-4">
        <AddButton onClick={handleAddItem} className="bg-vood hover:bg-vood-dark" />
        <RemoveButton onClick={handleRemoveItem} className="bg-vood hover:bg-vood-dark" />
      </div>
    </div>
  );
};

export default observer(CartPlanCard);
