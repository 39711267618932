import clsx from "clsx";
import React, { FC } from "react";

const CartItemLoading: FC = () => {
  return (
    <div
      className={clsx(
        "h-20 mt-2",
        "rounded-3xl",
        "border-2 border-gray-300",
        "text-charcoal",
        "hover:underline",
        "bg-vood-light",
        "flex items-center justify-center",
      )}
    >
      Loading ...
    </div>
  );
};

export default CartItemLoading;
