import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import CartItemCountTag from "src/modules/cart/components/CartItemCountTag";
import CartIcon from "src/modules/cart/icons/CartIcon";
import { useStore } from "src/services/store/StoreProvider";

const HeaderCartIconForMobile: FC = () => {
  const { setShowCart } = useStore();

  const handleOpen = () => setShowCart(true);

  return (
    <button
      onClick={handleOpen}
      className={clsx(
        "flex items-center justify-center",
        "w-[44px] h-[44px] ml-[7px] mr-[9px]",
        "text-white bg-black",
        "rounded-full",
        "hover:bg-gray-700",
        "relative",
      )}
    >
      <CartIcon />
      <CartItemCountTag />
    </button>
  );
};

export default observer(HeaderCartIconForMobile);
