import { validateYupSchema, yupToFormErrors } from "formik";
import { formatPhone } from "src/lib/GeneralLib";

/**
 * Formik validation to yup schema using a formatted Australian phone number
 */
export const validateWithPhone = (values, schema) => {
  try {
    validateYupSchema({ ...values, phone: formatPhone(values.phone) }, schema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};
