import { FirebaseError } from "firebase/app";
import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import yup, { emailField, passwordField } from "src/lib/YupLib";
import { SetterFunction } from "src/types";
import { InferType } from "yup";

import { authFormatFirebaseLoginError, loginWithEmail$ } from "../client";
import AuthField from "./AuthField";

const ThisSchema = yup
  .object({
    email: emailField.required(),
    password: passwordField.required(),
  })
  .defined();

export type ThisData = InferType<typeof ThisSchema>;

const LoginForm: FC<{ setOption: SetterFunction }> = ({ setOption }) => {
  const [thinking, setThinking] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleSubmit = async ({ email, password }) => {
    try {
      setThinking(true);
      await loginWithEmail$(email, password);
    } catch (err) {
      if (err instanceof FirebaseError) {
        const message = authFormatFirebaseLoginError(err);
        setError(message);
      } else {
        setError(err.message);
      }
    } finally {
      setThinking(false);
    }
  };

  const handleGotoForgotPassword = () => {
    setOption("reset");
  };

  const handleGotoCreateAccount = () => {
    setOption("create");
  };

  return (
    <Formik validationSchema={ThisSchema} initialValues={ThisSchema.cast({})} onSubmit={handleSubmit}>
      <Form className="flex flex-col items-stretch justify-between pt-6">
        <h2 className="w-full pb-6 text-3xl font-bold text-center text-white">Login</h2>

        <AuthField name="email" type="email" placeholder="Email*" />
        <AuthField name="password" type="password" placeholder="Password*" />

        {error && <div className="mb-5 font-mono text-sm text-red-600">{error}</div>}

        <div className="flex flex-col items-center justify-between mb-4 mt-4 md:flex-row">
          <button
            type="submit"
            disabled={thinking}
            className="block px-6 py-2 mb-4 text-white bg-black rounded-full hover:bg-charcoal md:mb-0 md:w-1/2 md:mr-3"
          >
            Log in
          </button>
          <button
            onClick={handleGotoCreateAccount}
            type="button"
            disabled={thinking}
            className="px-6 py-2 border border-black border-dashed rounded-full md:w-1/2 md:ml-4"
          >
            Create Account
          </button>
        </div>

        <div className="flex items-center justify-center">
          <button
            onClick={handleGotoForgotPassword}
            type="button"
            disabled={thinking}
            className="px-8 py-2 font-mono text-xs font-normal"
          >
            Forgot your password?
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginForm;
