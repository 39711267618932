import clsx from "clsx";
import React, { FC } from "react";
import ReactModal from "react-modal";
import CloseIcon from "src/images/CloseIcon";
import D from "src/images/D";
import O from "src/images/O";
import V from "src/images/V";

const Modal: FC<{
  isOpen: boolean;
  onClose;
  containerClassName?;
  contentClassName?;
  closeClassName?;
}> = ({ isOpen, onClose, containerClassName, closeClassName, contentClassName, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={clsx(
        "p-0",
        "md:absolute md:top-[50%] md:left-[50%]",
        "mr-[-50%]",
        "transform md:translate-x-[-50%] md:translate-y-[-50%]",
        "w-full md:max-w-[800px]",
        "md:overflow-visible",
        containerClassName,
      )}
      overlayClassName={clsx(
        "fixed top-0 bottom-0 left-0 right-0",
        "bg-black bg-opacity-90 z-50",
        "flex md:block items-center",
      )}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <div className={clsx("p-4 mx-4", "shadow-sm rounded-3xl", "bg-vood", contentClassName)}>
        <button
          onClick={onClose}
          aria-label="Close"
          className={clsx("absolute border border-white rounded-full -right-8 -top-2", closeClassName)}
        >
          <CloseIcon className="text-white" />
        </button>
        <div className="relative p-16 px-4 md:px-16 overflow-y-auto max-h-[85vh]">
          {children}
          <V className="hidden md:block absolute top-3 left-3" />
          <O className="hidden md:block absolute top-3 right-3" />
          <O className="hidden md:block absolute bottom-3 right-3" />
          <D className="hidden md:block absolute bottom-3 left-3" />
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
