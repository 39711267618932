import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { store } from "src/services/store/store";

import { toastError } from "../../../lib/ToastLib";
import { discountClientValidate$ } from "../../discount/client";

const CartDiscount: FC<{ inSidebar?: boolean }> = ({ inSidebar = true }) => {
  let storeDiscount = store?.plan?.discount || store?.discount;
  const [code, setCode] = useState(storeDiscount?.code);

  const itemsInCart = store?.plan || store?.cart ? true : false;

  useEffect(() => {
    setCode(storeDiscount?.code);
  }, [storeDiscount?.code, storeDiscount]);

  const checkDiscount = async (e) => {
    e.preventDefault();

    let discount = await discountClientValidate$(code);
    if (!discount) {
      toastError("Discount code invalid");
      return;
    }

    setCode(discount.code);
    if (store?.plan) {
      store.plan = {
        ...store.plan,
        discount,
      };
    }
    store.discount = discount;
  };

  const clearDiscount = (e) => {
    e.preventDefault();

    if (store?.plan) {
      store.plan = {
        ...store.plan,
        discount: null,
      };
    }

    store.discount = null;
    setCode("");
  };

  return (
    <div className={clsx("pb-8 pt-px -mt-4 border-b border-black border-dashed", inSidebar && "px-8 bg-charcoal")}>
      {itemsInCart && (
        <>
          <div
            className={clsx("py-6 px-5", "flex items-center justify-between", inSidebar ? "bg-black -mx-8" : "-mx-5")}
          >
            <div
              className={clsx(
                inSidebar ? "w-full" : "w-full sm:w-3/4",
                storeDiscount?.code && "px-2 bg-white",
                "flex items-center justify-between rounded-full",
                "relative",
              )}
            >
              <input
                type="text"
                className={clsx("px-4 py-3 text-charcoal rounded-full w-full text-[0.875rem] md:text-base")}
                placeholder="discount code"
                value={code}
                disabled={!!storeDiscount?.code}
                onKeyUp={(e) => {
                  e.key == "Enter" && checkDiscount(e);
                }}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              {storeDiscount?.code ? (
                <button
                  className={clsx(
                    "px-4 py-2 text-charcoal uppercase font-sharp-grotesk-medium leading-[1.25rem] text-xs rounded-full ",
                    "bg-vood hover:bg-vood-hover",
                  )}
                  onClick={clearDiscount}
                >
                  Remove
                </button>
              ) : (
                <button
                  className={clsx(
                    "px-4 py-2 text-charcoal uppercase font-sharp-grotesk-medium leading-[1.25rem] text-xs rounded-full",
                    "bg-vood hover:bg-vood-hover",
                    "absolute right-1.5 top-1.5",
                  )}
                  onClick={checkDiscount}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
          {storeDiscount?.message && (
            <div className={clsx("my-2", inSidebar ? "px-5 text-vood text-center" : "text-charcoal")}>
              {storeDiscount?.message}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default observer(CartDiscount);
