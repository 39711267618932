import React, { FC } from "react";

const V: FC<{ className }> = ({ className }) => {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9791 0.0830078L14.8947 12.2871L8.80673 0.0830078H0.87793L12.5379 23.4666H17.2514L28.9114 0.0830078H20.9791Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default V;
