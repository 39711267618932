import clsx from "clsx";
import React, { FC } from "react";

const ErrorText: FC<{ show?: boolean; className? }> = ({ show, className, children }) => {
  if (show === false || !children) return null;
  return (
    <div className={clsx("mt-2 mb-5", "font-mono text-xs text-red-600", "flex items-center justify-start", className)}>
      {children}
    </div>
  );
};

export default ErrorText;
