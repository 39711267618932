import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import Modal from "src/components/Modal";
import { useStore } from "src/services/store/StoreProvider";

const ModalQueue: FC = () => {
  const store = useStore();

  if (!store.modals.length) return null;

  // const modal = store.modals[0];

  const handleClose = () => {
    store.modals = [...store.modals.slice(1)];
  };

  return (
    <Modal isOpen={true} onClose={handleClose}>
      <div className="w-96">
        // TODO
        <div className="flex items-center justify-end mt-12">
          <button className="button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(ModalQueue);
