import currency from "currency.js";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";

import { isNumeric, isPercent } from "../../lib/GeneralLib";
import { currentDateISO } from "../../lib/TimeLib";
import { DISCOUNT_STATUS_ACTIVE, DiscountData } from "./schema";

export interface DiscountTotals {
  other: currency;
  plan: currency;
  delivery: currency;
  discount: currency;
  total: currency;
}

/**
 * Check we have a valid code
 * @param discount
 */
export const discountValidateCode = (discount: DiscountData) => {
  if (!discount) {
    return false;
  }

  if (discount.status != DISCOUNT_STATUS_ACTIVE) {
    return false;
  }

  const current = currentDateISO();

  if (DateTime.fromISO(current) < DateTime.fromISO(discount.dateActive).startOf("day")) {
    return false;
  }

  if (DateTime.fromISO(current) > DateTime.fromISO(discount.dateInactive).endOf("day")) {
    return false;
  }

  if (isEmpty(discount.amount) && discount.freeShipping === true) {
    return true;
  }

  if (isPercent(discount.amount) || isNumeric(discount.amount)) {
    return true;
  }

  return false;
};

/**
 * get the discount ammout
 * @param total
 * @param discount
 */
export const calculateDiscount = (total: currency, discount: DiscountData) => {
  if (!discountValidateCode(discount)) {
    return currency(0);
  }

  if (isPercent(discount.amount)) {
    return currency(-((parseFloat(discount.amount) / 100) * total.value));
  }

  if (isNumeric(discount.amount)) {
    return currency(-parseFloat(discount.amount));
  }

  return currency(0);
};

export const discountAdjustTotals = (totals: DiscountTotals, discount: DiscountData = null) => {
  if (!discount) {
    return totals;
  }

  if (discount.code) {
    let subTotal = totals.plan.add(totals.other);
    totals.discount = calculateDiscount(subTotal, discount);
    totals.total = totals.total.add(totals.discount);

    if (discount.freeShipping) {
      totals.total = totals.total.subtract(totals.delivery);
      totals.delivery = currency(0);
    }
  }

  return totals;
};
