import { apiGet$, apiPost$ } from "src/lib/APILib";

import { DiscountData } from "./schema";

//import { DiscountData } from "./schema";

export const discountClientByCode$ = async (code: string) => {
  return apiGet$(["discount", "code", code]);
};

export const discountClientValidate$ = async (code: string) => {
  return apiPost$(["discount", "validate"], { code }) as Promise<DiscountData>;
};
