import NextLink from "next/link";
import React, { CSSProperties, FC, ReactNode } from "react";

const Link: FC<{
  href: string;
  query?: any;
  className?: any;
  style?: CSSProperties;
  props?: any;
  children: ReactNode;
}> = ({ href, query = {}, children, ...props }) => {
  return (
    <NextLink href={{ pathname: href, query }}>
      <a {...props}>{children}</a>
    </NextLink>
  );
};

export default Link;
