import React, { FC } from "react";

import QtyButton from "./QtyButton";

const RemoveButton: FC<{ onClick; className? }> = ({ onClick, className }) => {
  return (
    <QtyButton onClick={onClick} className={className}>
      <svg width="10" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2H0V0H14V2Z" fill="currentColor" />
      </svg>
    </QtyButton>
  );
};

export default RemoveButton;
