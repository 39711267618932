import { get, set } from "lodash";

/**
 * Conditionally add to an object
 *
 * const obj = {
 *   ...otherObj,
 *   ...objIf(isRaining, {key1, key2, })
 *   }
 */

export const objIf = (condition: boolean, values) => (condition ? values : {});

/**
 * Conditionally add to an array
 *
 * const arr = [
 *   ...otherArr,
 *   ...arrIf(isRaining, [key1, key2, ])
 *   ]
 */

export const arrIf = (condition: boolean, values) => (condition ? values : []);

/**
 * Group an array of objects by keys
 *
 * [{a,b,c},...], ['a','b'] => {a:{b:[{a,b,c},..]}}
 */

export const arrRollup = (arr, fields: string[]) => {
  const result: any = {};
  arr.forEach((item) => {
    const idx = fields.map((field) => item[field]);
    const cur = get(result, idx, []);
    set(result, idx, [...cur, item]);
  });
  return result;
};

/**
 * Removes leading zeros and +61
 */
export const basePhone = (phone: string) => {
  return `${phone.replace(/^0+|\+61/, "")}`;
};

/**
 * Removes leading zeros and add +61 prefix
 */
export const formatPhone = (phone: string, prefix: string = "+61") => {
  return `${prefix}${basePhone(phone)}`;
};

/**
 * Simple html encoder
 */
export const htmlspecialchars = (text: string) => {
  return text.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
};

/**
 * Removes html tags from text
 */
export const stripTags = (text: string) => {
  return text.replace(/<[^>]*>?/gm, "");
};

/**
 * Check if value is numeric
 * @param text
 */
export const isNumeric = (text: any) => {
  if (typeof text != "string") return false; // we only process strings!

  return (
    !isNaN(Number(text)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(text))
  ); // ...and ensure strings of whitespace fail
};

export const isPercent = (text: any) => {
  if (text.includes("%")) {
    return isNumeric(text.replace("%", ""));
  }

  return false;
};
