import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { useStore } from "src/services/store/StoreProvider";

import { logout$ } from "../client";

const LoginButton: FC = () => {
  const { isLoggedIn, setShowLogin } = useStore();

  const handleLogout = async () => {
    await logout$();
  };

  if (isLoggedIn) {
    return (
      <button
        className="px-4 py-6 leading-none text-xs uppercase rounded-full opacity-50 hover:underline hover:text-black font-sharp-grotesk-medium text-charcoal"
        onClick={handleLogout}
      >
        Logout
      </button>
    );
  }

  const handleOpen = () => setShowLogin(true);

  return (
    <>
      <button
        className="px-4 py-6 leading-none text-xs uppercase rounded-full opacity-50 hover:underline hover:text-black font-sharp-grotesk-medium text-charcoal"
        onClick={handleOpen}
      >
        Login
      </button>
    </>
  );
};

export default observer(LoginButton);
