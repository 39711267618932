import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { cartGetTotals } from "src/modules/cart/client";
import { store } from "src/services/store/store";

const CartTotal: FC = () => {
  const totals = cartGetTotals();
  const itemsInCart = store?.plan || store?.cart ? true : false;

  return (
    <div className={clsx("bg-charcoal", "px-8", "pb-2", "pt-px")}>
      {itemsInCart && (
        <>
          {store?.plan && (
            <div
              className={clsx(
                "w-full mt-6 pb-1",
                "text-vood-light text-sm font-mono",
                "border-b border-dashed border-white",
                "flex items-end justify-between",
              )}
            >
              <span>Your plan</span>
              <span>{totals.plan.format()}</span>
            </div>
          )}
          <div
            className={clsx(
              "w-full mt-6 pb-1",
              "text-vood-light text-sm font-mono",
              "border-b border-dashed border-vood-light",
              "flex items-end justify-between",
            )}
          >
            <span>{store?.plan ? "Your extras" : "Your Items"}</span>
            <span>{totals.other.format()}</span>
          </div>
          <div
            className={clsx(
              "w-full mt-6 pb-1",
              "text-vood-light text-sm font-mono",
              "border-b border-dashed border-vood-light",
              "flex items-end justify-between",
            )}
          >
            <span>Delivery</span>
            <span>{totals.delivery.value === 0 && store?.plan ? "FREE" : totals.delivery.format()}</span>
          </div>

          {totals.discount.value !== 0 && (
            <div
              className={clsx(
                "w-full mt-6 pb-1",
                "text-vood-light text-sm font-mono",
                "border-b border-dashed border-vood-light",
                "flex items-end justify-between",
              )}
            >
              <span>
                <span className="text-sm">Discount </span>
              </span>
              <span>{totals.discount.format()}</span>
            </div>
          )}
        </>
      )}

      <div
        className={clsx(
          "w-full mt-6 pb-1",
          "text-vood-bright text-base leading-none",
          "border-b border-solid border-vood-bright",
          "flex items-end justify-between",
        )}
      >
        <span className="font-mono">
          <span className="text-sm">Total </span>
          <span className="text-3xs">(GST inclusive)</span>
        </span>
        <span className="text-2xl font-sharp-grotesk-medium leading-none">{totals.total.format()}</span>
      </div>
    </div>
  );
};

export default observer(CartTotal);
