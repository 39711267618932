import React, { FC } from "react";

const CartIcon: FC = () => {
  return (
    <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4">
      <path
        d="M14.5659 8.23559L15.8585 2.5644C15.9519 2.15493 15.6397 1.765 15.2186 1.765H4.47838L4.22774 0.543242C4.16529 0.238715 3.89655 0.0200195 3.58481 0.0200195H0.781255C0.418811 0.0200195 0.125 0.312986 0.125 0.674388V1.11063C0.125 1.47204 0.418811 1.765 0.781255 1.765H2.69213L4.61299 11.1289C4.15345 11.3924 3.84378 11.8865 3.84378 12.453C3.84378 13.2963 4.52935 13.9799 5.37504 13.9799C6.22074 13.9799 6.9063 13.2963 6.9063 12.453C6.9063 12.0257 6.73002 11.6396 6.44627 11.3624H12.1789C11.8951 11.6396 11.7188 12.0257 11.7188 12.453C11.7188 13.2963 12.4044 13.9799 13.2501 13.9799C14.0958 13.9799 14.7814 13.2963 14.7814 12.453C14.7814 11.8485 14.429 11.3261 13.9179 11.0787L14.0687 10.4168C14.1621 10.0074 13.8499 9.61743 13.4288 9.61743H6.08918L5.91022 8.74494H13.926C14.2324 8.74494 14.498 8.53352 14.5659 8.23559Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CartIcon;
