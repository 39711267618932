import clsx from "clsx";
import { Form, Formik, FormikHelpers } from "formik";
import React, { FC, useState } from "react";
import TextField from "src/components/form/TextField";
import yup from "src/lib/YupLib";
import { handle$ } from "src/modules/base/handle";
import { signupClientSave$ } from "src/modules/signup/client";
import { InferType } from "yup";

const ThisSchema = yup
  .object({
    email: yup.string().email("Please enter a valid email address").required().default(""),
  })
  .defined();

export type ThisData = InferType<typeof ThisSchema>;

const SignUpForm: FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [thinking, setThinking] = useState<boolean>(false);

  const handleSubmit = async (values: ThisData, actions: FormikHelpers<ThisData>) =>
    handle$(
      async () => {
        await signupClientSave$({ ...values, source: "signupform" });
        console.log("handleSubmit", { values });
        actions.resetForm();
        setSubmitted(true);
      },
      { setThinking },
    );

  if (submitted) {
    return (
      <div
        className={clsx(
          "flex items-center justify-center sm:justify-start",
          "py-2 sm:py-2",
          "text-black",
          "text-[12px] font-bold",
          "font-mono",
        )}
        onDoubleClick={() => setSubmitted(false)}
      >
        Welcome aboard, Voodie!
      </div>
    );
  }

  return (
    <Formik validationSchema={ThisSchema} initialValues={ThisSchema.cast({})} onSubmit={handleSubmit}>
      <Form>
        <div className={clsx("flex justify-center sm:justify-start items-start", "gap-1")}>
          <TextField
            type="text"
            name="email"
            placeholder="Email*"
            disabled={thinking}
            className={clsx(
              "px-4 py-2",
              "placeholder-black",
              "border border-charcoal rounded-3xl",
              "text-xs text-black",
              "font-sharp-grotesk-medium",
            )}
          />
          <input
            type="submit"
            disabled={thinking}
            value="sign up"
            className={clsx(
              "px-6 py-2",
              "text-xs text-white uppercase",
              "bg-black hover:bg-charcoal disabled:bg-gray-300",
              "rounded-full",
              "font-sharp-grotesk-medium",
              "hover:cursor-pointer",
            )}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default SignUpForm;
