import clsx from "clsx";
import React, { FC } from "react";

import SignUpForm from "./SignUpForm";

const BecomeAVoodie: FC<{ className }> = ({ className }) => {
  return (
    <div
      className={clsx(
        className,
        "flex justify-center sm:justify-stretch items-stretch sm:items-start",
        "flex-col",
        "gap-4",
      )}
    >
      <h2 className={clsx("text-center sm:text-left text-xs text-charcoal/80", "font-sharp-grotesk-medium")}>
        BECOME A VOODIE
      </h2>
      <span className="text-center sm:text-left font-mono text-xs text-charcoal/80">
        Sign up to our newsletter for updates
        <br />
        deals, and more, direct to your inbox.
      </span>
      <SignUpForm />
    </div>
  );
};

export default BecomeAVoodie;
