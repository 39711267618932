import React, { FC } from "react";

const O: FC<{ className }> = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0238 12.0822C17.0219 13.3808 16.5055 14.6257 15.5876 15.5442C14.6697 16.4628 13.4252 16.9801 12.1266 16.9829C10.8256 16.9839 9.57744 16.4682 8.65649 15.5492C7.73553 14.6302 7.21714 13.3832 7.21527 12.0822C7.21573 11.4378 7.34316 10.7999 7.59028 10.2049C7.83739 9.60979 8.19935 9.06925 8.65545 8.61413C9.11156 8.159 9.65287 7.79822 10.2485 7.55239C10.8441 7.30657 11.4823 7.18052 12.1266 7.18145C13.4255 7.18332 14.6705 7.70036 15.5886 8.61912C16.5067 9.53788 17.0229 10.7833 17.0238 12.0822ZM24.1223 12.0822C24.1223 10.5046 23.8115 8.9424 23.2078 7.48488C22.6041 6.02735 21.7192 4.70301 20.6036 3.58747C19.4881 2.47193 18.1638 1.58704 16.7062 0.983308C15.2487 0.379582 13.6865 0.0688477 12.1089 0.0688477C8.92585 0.0735286 5.87477 1.34128 3.62565 3.59371C1.37653 5.84614 0.113278 8.89911 0.113281 12.0822C0.113278 15.2653 1.37653 18.3182 3.62565 20.5707C5.87477 22.8231 8.92585 24.0908 12.1089 24.0955C15.292 24.0955 18.345 22.8323 20.5974 20.5831C22.8498 18.334 24.1176 15.2829 24.1223 12.0998"
        fill="#231F20"
      />
    </svg>
  );
};

export default O;
