import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { FC, useState } from "react";
import Modal from "react-modal";
import CrossIcon from "src/images/CrossIcon";
import Link from "src/layout/Link";
import { logout$ } from "src/modules/auth/client";
import { useStore } from "src/services/store/StoreProvider";

import HamburgerIcon from "./HamburgerIcon";
import VoodLogo from "./VoodLogo";

const MyLink: FC<{
  onClick;
  topBorder?: boolean;
  isSubMenu?: boolean;
  className?: string;
}> = ({ onClick, topBorder = true, isSubMenu = false, className = null, children }) => {
  return (
    <button
      className={clsx(
        "w-full px-2 py-2 my-1",
        "text-center",
        "font-sharp-grotesk-medium",
        {
          "text-3xl": !isSubMenu,
          "text-xl": isSubMenu,
        },
        "hover:underline",
        { "border-t border-dashed border-black": topBorder && !isSubMenu },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const MainMenuModal: FC = () => {
  const { isLoggedIn, setShowLogin, showMainMenu, setShowMainMenu } = useStore();
  const router = useRouter();
  const [showAbout, setShowAbout] = useState<boolean>(false);

  const handleShowAbout = () => setShowAbout(!showAbout);

  //const handleOpen = () => setShowMainMenu(true);

  const handleToggle = () => setShowMainMenu(!showMainMenu);

  const handleClose = () => setShowMainMenu(false);

  const handleOnClickHref = (href) => () => {
    router.push(href);
    handleClose();
  };

  const handleLogin = () => {
    setShowLogin(true);
    handleClose();
  };

  const handleLogout = async () => {
    await logout$();
    handleClose();
  };

  return (
    <>
      <button
        onClick={handleToggle}
        className={clsx(
          "flex items-center justify-center",
          "h-[60px] w-[60px]",
          "rounded-full",
          "hover:bg-gray-200",
          "text-charcoal",
        )}
      >
        <HamburgerIcon />
      </button>

      {showMainMenu && (
        <Modal
          isOpen={true}
          className={clsx("absolute top-0 left-0 right-0", "")}
          overlayClassName={clsx("fixed top-0 bottom-0 left-0 right-0", "bg-black bg-opacity-90 z-50")}
          onRequestClose={handleClose}
          shouldCloseOnOverlayClick={true}
        >
          <div className="m-4 rounded-3xl bg-vood">
            <div className="rounded-3xl bg-vood-light">
              <div className="flex items-center justify-between bg-white rounded-3xl">
                <div className="w-12 h-12"> </div>

                <button onClick={handleOnClickHref("/")}>
                  <VoodLogo className={clsx("h-6")} />
                </button>

                <button
                  onClick={handleClose}
                  className={clsx("h-12 w-12", "hover:bg-gray-200", "rounded-3xl", "flex justify-center items-center")}
                >
                  <CrossIcon />
                </button>
              </div>

              <div className="flex flex-col items-center justify-center gap-6 py-8">
                {isLoggedIn ? (
                  <>
                    <button
                      onClick={handleLogout}
                      className="text-center uppercase font-sharp-grotesk-medium hover:underline"
                    >
                      Sign out
                    </button>
                    <button
                      onClick={handleOnClickHref("/profile")}
                      className="text-center uppercase font-sharp-grotesk-medium hover:underline"
                    >
                      Profile
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleLogin}
                    className="text-center uppercase font-sharp-grotesk-medium hover:underline"
                  >
                    Sign in
                  </button>
                )}

                <button
                  onClick={handleOnClickHref("/start")}
                  className={clsx(
                    "px-6 py-4",
                    "rounded-full",
                    "text-white hover:text-white text-xs uppercase",
                    "bg-[#EE5B5B] hover:bg-black hover:text-white",
                    "font-sharp-grotesk-medium",
                    "transition-colors duration-200",
                    "text-center",
                    "w-52",
                  )}
                >
                  Get started
                </button>
              </div>
            </div>

            <div className="flex flex-col px-6">
              <MyLink
                onClick={handleOnClickHref("/menu")}
                topBorder={false}
                className="px-6 pt-12 border-b border-dashed border-black"
              >
                Menu
              </MyLink>

              <MyLink topBorder={false} onClick={handleShowAbout}>
                About
              </MyLink>

              {showAbout && (
                <div>
                  <MyLink onClick={handleOnClickHref("/read/about")} isSubMenu={true}>
                    About Vood
                  </MyLink>
                  <MyLink onClick={handleOnClickHref("/read/why-vood")} isSubMenu={true}>
                    Why Vood
                  </MyLink>
                  <MyLink onClick={handleOnClickHref("/read/ethos")} isSubMenu={true}>
                    Vood Ethos
                  </MyLink>
                </div>
              )}

              <MyLink onClick={handleOnClickHref("/checkout")} className="pb-12">
                Checkout
              </MyLink>
            </div>
          </div>

          <div className="flex px-12 ">
            <div className="w-1/2">
              <Link href="/read/faq" className="block font-mono text-sm text-white hover:underline">
                FAQ
              </Link>
              <Link href="/read/terms" className="block font-mono text-sm text-white hover:underline">
                Terms & Conditions
              </Link>
              <Link href="/read/ethos" className="block font-mono text-sm text-white hover:underline">
                Vood Ethos
              </Link>
              <Link href="/read/why" className="block font-mono text-sm text-white hover:underline">
                Why Vood
              </Link>
            </div>
            <div className="w-1/2">
              <Link
                href="https://www.instagram.com/eatvood/"
                className="block font-mono text-sm text-right text-white hover:underline"
              >
                Instagram
              </Link>
              <Link
                href="https://www.facebook.com/eatvood"
                className="block font-mono text-sm text-right text-white hover:underline"
              >
                Facebook
              </Link>
              <Link
                href="https://www.linkedin.com/company/eatvood/"
                className="block font-mono text-sm text-right text-white hover:underline"
              >
                LinkedIn
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCa-BS5cP7OfF3ywhRM-2d7A"
                className="block font-mono text-sm text-right text-white hover:underline"
              >
                Youtube
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default observer(MainMenuModal);
