import { InferType } from "yup";

import yup, { dateStrField, idField } from "../../lib/YupLib";
import { IDFIELD } from "../base/schema";

//import { InferType } from "yup";
//import { VariantSchema } from "../product/VariantLib";

export const DISCOUNT_TABLE_NAME = "discount";

export const DISCOUNT_STATUS_ACTIVE = "active";
export const DISCOUNT_STATUS_INACTIVE = "inactive";

export const DiscountSchema = yup.object({
  [IDFIELD]: idField.optional(),
  dateActive: dateStrField.required(),
  dateInactive: dateStrField.required(),
  status: yup.string().oneOf([DISCOUNT_STATUS_ACTIVE, DISCOUNT_STATUS_INACTIVE]).required(),
  message: yup.string().nullable().optional().default(null),
  amount: yup.string().nullable().default(null),
  freeShipping: yup.boolean().nullable().default(false),
  userId: yup.string().nullable().optional().default(null),
  code: yup.string().required(),
  created: yup.number().optional(),
});

export type DiscountData = InferType<typeof DiscountSchema>;
