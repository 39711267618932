import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import Link from "src/layout/Link";

const Block: FC<{ href: string; children: ReactNode }> = ({ href, children }) => {
  return (
    <li>
      <Link href={href} className="font-mono text-xs text-charcoal/80">
        {children}
      </Link>
    </li>
  );
};

const LeftLinks: FC<{ className }> = ({ className }) => {
  return (
    <div
      className={clsx(
        className,
        "text-center sm:text-left",
        "py-[40px] sm:py-0",
        "border-t border-r sm:border-0 border-vood-dark",
      )}
    >
      <h3 className="text-xs uppercase font-sharp-grotesk-medium text-black pb-1 tracking-wide">Quick Links</h3>
      <ol>
        <Block href="/read/about">About Vood</Block>
        <Block href="/read/why-vood">Why Vood</Block>
        <Block href="/read/ethos">Vood Ethos</Block>
        <Block href="/read/faq">FAQ</Block>
        <Block href="/contact">Contact Us</Block>
      </ol>
    </div>
  );
};

export default LeftLinks;
