import clsx from "clsx";
import { Field, useField, useFormikContext } from "formik";
import React, { FC, useState } from "react";

import ErrorText from "../ErrorText";

export interface PhoneFieldProps {
  name: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  className?;
  wrapperClass?;
  append?;
}

const PhoneField: FC<PhoneFieldProps> = ({
  name,
  type = "text",
  placeholder = "",
  disabled = false,
  className,
  wrapperClass,
  append,
}) => {
  const [{ value, ...rest }, { error, touched }] = useField(name);
  const { isSubmitting, setFieldTouched } = useFormikContext();
  const [focused, setFocused] = useState(!!value);

  const handleBlur = (e) => {
    !value && setFocused(false);
    setFieldTouched(name, true);
  };

  return (
    <>
      <div className={clsx(wrapperClass)}>
        <div className="flex items-center gap-2">
          {focused && <div>+61</div>}
          <Field
            {...rest}
            value={value || ""}
            type={type}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={handleBlur}
            placeholder={placeholder}
            disabled={disabled || isSubmitting}
            className={clsx("flex-shrink flex-grow focus:outline-none", className)}
          />
          {append}
        </div>
      </div>
      <ErrorText show={touched}>{error}</ErrorText>
    </>
  );
};

export default PhoneField;
