import { FirebaseError } from "firebase/app";
import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import yup, { emailField } from "src/lib/YupLib";
import { SetterFunction } from "src/types";
import { InferType } from "yup";

import { authFormatFirebaseLoginError, loginResetPassword$ } from "../client";
import AuthField from "./AuthField";

const ThisSchema = yup
  .object({
    email: emailField.required(),
  })
  .defined();

export type ThisData = InferType<typeof ThisSchema>;

const ResetPasswordForm: FC<{ setOption: SetterFunction }> = ({ setOption }) => {
  const [thinking, setThinking] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async ({ email }) => {
    try {
      setThinking(true);
      await loginResetPassword$(email);
      setSuccess(true);
      setError(null);
    } catch (err) {
      // console.error("err", err);
      if (err instanceof FirebaseError) {
        const message = authFormatFirebaseLoginError(err);
        setError(message);
      } else {
        setError(err.message);
      }
      setSuccess(false);
    } finally {
      setThinking(false);
    }
  };

  const handleGotoLogin = () => setOption("login");

  return (
    <Formik validationSchema={ThisSchema} initialValues={ThisSchema.cast({})} onSubmit={handleSubmit}>
      <Form className="flex flex-col items-stretch justify-between pt-6">
        <h2 className="w-full pb-6 text-3xl font-bold text-center text-white">Reset your password</h2>

        <AuthField name="email" type="email" placeholder="Email*" />

        {success && (
          <div className="mb-5 font-mono text-sm text-green-600">
            Success!!
            <br />
            We’ve sent an email with password reset instructions.
          </div>
        )}

        {error && <div className="mb-5 font-mono text-sm text-red-600">{error}</div>}

        <div className="flex items-center justify-center mb-4">
          <button
            type="submit"
            disabled={thinking}
            className="block px-6 py-2 mb-4 text-white bg-black rounded-full hover:bg-charcoal md:mb-0 md:w-1/2"
          >
            Reset Password
          </button>
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={handleGotoLogin}
            type="button"
            disabled={thinking}
            className="px-8 py-2 font-mono text-xs font-normal"
          >
            Return to login
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
