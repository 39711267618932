import React, { FC } from "react";

const VoodLogo: FC<{ className? }> = ({ className }) => {
  return (
    <svg fill="none" viewBox="0 0 121 30" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M84.0319 15C84.0319 18.383 81.2785 21.1277 77.8848 21.1277C74.4911 21.1277 71.7377 18.383 71.7377 15C71.7377 11.617 74.4911 8.87234 77.8848 8.87234C81.2572 8.87234 84.0319 11.617 84.0319 15ZM92.9324 15C92.9324 6.7234 86.1877 0 77.8848 0C69.5819 0 62.8372 6.7234 62.8372 15C62.8372 23.2766 69.5819 30 77.8848 30C86.1663 30 92.9324 23.2766 92.9324 15ZM46.3595 8.87234C42.9658 8.87234 40.2124 11.617 40.2124 15C40.2124 18.383 42.9658 21.1277 46.3595 21.1277C49.7532 21.1277 52.5066 18.383 52.5066 15C52.5066 11.617 49.7532 8.87234 46.3595 8.87234ZM46.3595 30C38.0566 30 31.3119 23.2766 31.3119 15C31.3119 6.7234 38.0566 0 46.3595 0C54.6624 0 61.4071 6.7234 61.4071 15C61.4071 23.2766 54.6624 30 46.3595 30ZM103.199 9.25532H106.337C109.517 9.25532 112.099 11.8298 112.099 15C112.099 18.1702 109.517 20.7447 106.337 20.7447H103.199V9.25532ZM121 15C121 6.93617 114.426 0.382979 106.337 0.382979H94.2985V29.617H106.337C114.426 29.617 121 23.0638 121 15ZM25.2074 0.382979L17.5876 15.6383L9.94637 0.382979H0L14.6207 29.617H20.5331L35.1538 0.382979H25.2074Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VoodLogo;
