import clsx from "clsx";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { useStore } from "src/services/store/StoreProvider";

const CartCheckoutButton: FC = () => {
  const router = useRouter();
  const { setShowCart } = useStore();

  if (router.route === "/checkout") {
    return <div className={clsx("bg-charcoal", "h-6")}> </div>;
  }

  const handleClick = () => {
    router.push("/checkout");
    setShowCart(false);
  };

  return (
    <div className={clsx("p-6", "bg-charcoal")}>
      <button onClick={handleClick} className={clsx("btn btn-purple", "w-full h-[44px]")}>
        Checkout
      </button>
    </div>
  );
};

export default CartCheckoutButton;
