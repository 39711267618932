import clsx from "clsx";
import React, { FC } from "react";
import Container from "src/layout/Container";
import Link from "src/layout/Link";

import BecomeAVoodie from "./BecomeAVoodie";
import LeftLinks from "./LeftLinks";
import LogoAndText from "./LogoAndText";
import RightLinks from "./RightLinks";

const Footer: FC = () => {
  return (
    <div className={clsx("w-full", "bg-white")}>
      <Container className={clsx("flex flex-wrap", "pt-[40px] sm:pt-[83px] pb-[42px]")}>
        <BecomeAVoodie className={clsx("w-full sm:w-1/3", "pb-6", "order-1 sm:order-4")} />
        <LeftLinks className={clsx("w-1/2 sm:w-1/6", "order-2 sm:order-2")} />
        <RightLinks className={clsx("w-1/2 sm:w-1/6", "order-3 sm:order-3")} />
        <LogoAndText className={clsx("w-full sm:w-1/3", "order-4 sm:order-1")} />
      </Container>

      <Container
        className={clsx(
          "flex flex-wrap",
          "flex-col sm:flex-row",
          "sm:pt-[40px] pb-[40px] sm:pb-[89px]",
          "sm:border-t border-vood-dark",
        )}
      >
        <div className={clsx("w-full sm:w-1/3", "text-center sm:text-left font-mono text-xs text-charcoal/80")}>
          © 2022 Vood. All rights reserved
        </div>
        <Link
          href="/read/terms"
          className={clsx("w-full sm:w-1/6", "text-center sm:text-left font-mono text-xs text-charcoal/80")}
        >
          Terms & Conditions
        </Link>
        <Link
          href="/read/privacy"
          className={clsx("w-full sm:w-1/6", "text-center sm:text-left font-mono text-xs text-charcoal/80")}
        >
          Privacy Policy
        </Link>
        <div className={clsx("w-full sm:w-1/3")}> </div>
      </Container>
    </div>
  );
};

export default Footer;
