import currency from "currency.js";
import { DateTime } from "luxon";
import {  TIMEZONE } from "../../config/appConfig";
import { currentDateISO } from "../../lib/TimeLib";
import { AddressData } from "../general/address-schema";
import { getRegion, postcodes } from "./postcodes";
import { ShippingZonesData } from "./schema";

export const DELIVERY_MIN_PER_DELIVERY = 3;
export const DELIVERY_MIN_PER_DELIVERY_TEXT = "three";

export const DELIVERY_COST_PLAN = 12;
export const DELIVERY_COST_EMPTY_CART = 0;
export const DELIVERY_COST_ADHOC = 12;

export const postcodeIsValid = (postcode: string) => postcode in postcodes;

export const deliveryGetCost = (
  isPlan: boolean,
  cartItemCount: number,
): currency => {
  return currency(
    isPlan
      ? DELIVERY_COST_PLAN
      : cartItemCount === 0
      ? DELIVERY_COST_EMPTY_CART
      : DELIVERY_COST_ADHOC
  );
};

/**
 * - given a date and postcode
 * - determine the dates that will be next delivered
 */

export const deliveryGetDates = (
  postcode: string,
  date: string,
  address: AddressData,
  shippingZones: ShippingZonesData[]

): null | DateTime[] => {
  const region = postcodes[postcode];
  const te = getRegion(shippingZones, address);
  const cutOff = te.cutOff;


  if (!region) return null;

  if (!date) date = currentDateISO();

  const today = DateTime.fromISO(date).setZone(TIMEZONE);

  // work out if we need to skip the first week based on the first delivery slot
  const skipWeek = today.weekday + cutOff >= region.delivery[0];

  // if we need to skip the week lets start from the monday of the next week
  const startOffset = skipWeek ? 7 - today.weekday + 1 : cutOff;

  const baseTime = DateTime.fromISO(date).setZone(TIMEZONE).plus({ days: startOffset });

  const baseSlot = baseTime.weekday;

  // loop through delivery slots
  const dates = region.delivery
    .map((thisSlot) => {
      let diff = thisSlot - baseSlot;

      while (diff < 1) diff += 7;

      return baseTime.plus({ days: diff });
    })
    .sort((a, b) => a.valueOf() - b.valueOf());

  return dates;
};

/**
 * - given a date and postcode
 * - determine the dates that will be next delivered
 */

export const deliveryNationalGetDates = (
  date: string,
  address: AddressData,
  shippingZones: ShippingZonesData[],
): null | DateTime[] => {
  if (!date) date = currentDateISO();

  const today = DateTime.fromISO(date).setZone(TIMEZONE);

  const region = getRegion(shippingZones, address);
  const delivery = region.delivery;
  const cutOff = region.cutOff;

  // work out if we need to skip the first week based on the first delivery slot
  //const skipWeek = today.weekday + DELIVERYCUTOFFDAYS >= PLAN_DELIVERY_DAYS[0];
  const skipWeek = today.weekday + cutOff >= delivery[0];

  // if we need to skip the week lets start from the monday of the next week
  const startOffset = skipWeek ? 7 - today.weekday + 1 : cutOff;

  const baseTime = DateTime.fromISO(date).setZone(TIMEZONE).plus({ days: startOffset });

  const baseSlot = baseTime.weekday;

  // loop through delivery slots
  //const dates = PLAN_DELIVERY_DAYS.map((thisSlot) => {
  return delivery
    .map((thisSlot) => {
      let diff = thisSlot - baseSlot;

      while (diff < 1) diff += 7;

      return baseTime.plus({ days: diff });
    })
    .sort((a, b) => a.valueOf() - b.valueOf());
};
