import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import Modal from "react-modal";
import CrossIcon from "src/images/CrossIcon";
import { useStore } from "src/services/store/StoreProvider";

import CartParts from "./CartParts";

const ModalCart: FC = () => {
  const { showCart, setShowCart } = useStore();

  if (!showCart) return null;

  const handleClose = () => setShowCart(false);

  return (
    <Modal
      isOpen={showCart}
      className={clsx(
        "p-6",
        "lg:absolute top-0 lg:left-[50%]",
        "lg:mr-[-50%]",
        "transform lg:translate-x-[-50%]",
        "w-full max-w-[600px]",
      )}
      overlayClassName={clsx("fixed top-0 bottom-0 left-0 right-0", "bg-black bg-opacity-90 z-50", "overflow-auto")}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
    >
      <div className="max-w-96 mx-auto pb-48 relative">
        <button
          onClick={handleClose}
          className="flex-shrink-0 h-8 px-2 absolute right-2 top-2 text-sm bg-white hover:bg-gray-100 rounded-full text-black flex justify-center items-center"
        >
          <CrossIcon />
        </button>
        <CartParts />
      </div>
    </Modal>
  );
};

export default observer(ModalCart);
