import clsx from "clsx";
import { map } from "lodash";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { cartRemoveAllOf, cartRollup } from "src/modules/cart/client";
import { PRODUCT_TYPE_MAIN } from "src/modules/product/ProductTypeLib";
import { VariantType } from "src/modules/product/VariantLib";
import { useStore } from "src/services/store/StoreProvider";

import { CartItem } from "../schema";
import CartItemLoading from "./CartItemLoading";
import CartItemsMainsCard from "./CartItemsMainsCard";
import CartItemsPantryCard from "./CartItemsPantryCard";
import CartNoItemsMessage from "./CartNoItemsMessage";

const CartItemsSection: FC<{ items: CartItem[] }> = ({ items }) => {
  const { products, plan } = useStore();
  const rollup = cartRollup(items);

  const renderItems = () => {
    if (!products) return <CartItemLoading />;

    return map(rollup, (variants, productId) => {
      return map(variants, (qty, variant) => {
        const product = products[productId];

        if (!product) {
          // deleted product - remove from cart to stop error
          cartRemoveAllOf(productId);
          return null;
        }

        if (product.type === PRODUCT_TYPE_MAIN)
          return (
            <CartItemsMainsCard
              key={productId + variant}
              productId={productId}
              variant={variant as VariantType}
              quantity={qty}
            />
          );

        return (
          <CartItemsPantryCard
            key={productId + variant}
            productId={productId}
            variant={variant as VariantType}
            quantity={qty}
          />
        );
      });
    });
  };

  return (
    <div className="p-2.5 pb-5 bg-white border-1 border-grey rounded-t-[1.875rem] shadow-cart">
      <h2
        className={clsx("mt-8 mb-5", "text-3xl lg:text-[1.875rem] text-center font-sharp-grotesk-medium", {
          "mb-2": !plan,
        })}
      >
        {plan ? "Extras" : "Items"}
      </h2>
      {items.length ? renderItems() : <CartNoItemsMessage />}
    </div>
  );
};

export default observer(CartItemsSection);
