import React, { FC } from "react";
import LottieProgressSpinner from "src/layout/LottieProgressSpinner";

const LoadingSection: FC<{}> = ({ children }) => {
  return (
    <div className="flex items-center justify-center gap-6 h-96">
      <LottieProgressSpinner />
      <div>{children}</div>
    </div>
  );
};

export default LoadingSection;
