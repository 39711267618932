import clsx from "clsx";
import currency from "currency.js";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import AddButton from "src/components/button/AddButton";
import RemoveButton from "src/components/button/RemoveButton";
import Link from "src/layout/Link";
import { cartAddItem, cartRemoveProductId } from "src/modules/cart/client";
import { productGetBackgroundSrc, productGetPrice, productGetURI, productHasImage } from "src/modules/product/lib";
import { VARIANT_EACH, VariantType } from "src/modules/product/VariantLib";
import { useStore } from "src/services/store/StoreProvider";

const CartItemsPantryCard: FC<{
  productId: string;
  variant: VariantType;
  quantity: number;
}> = ({ productId, variant, quantity }) => {
  // console.log("CartItemsCard", { productId, variant });

  const store = useStore();
  const product = store.products[productId];

  const handleAddItem = () => {
    cartAddItem(productId, variant);
  };

  const handleRemoveItem = () => {
    cartRemoveProductId(productId, variant);
  };

  const price = productGetPrice(product, variant);
  const total = currency(price).multiply(quantity);

  return (
    <div
      className={clsx(
        "min-h-20 mt-1 p-2",
        "rounded-[1.875rem]",
        "text-charcoal",
        "bg-vood-light",
        "flex items-center justify-between",
      )}
    >
      <div className="flex-grow px-4 py-2">
        <h3 className="font-sharp-grotesk-medium leading-[1.25rem]">{product.title}</h3>
        <p className="font-mono text-sm pt-1">
          {variant === VARIANT_EACH ? "" : `${variant} `}
          {total.format()}
        </p>
      </div>

      {productHasImage(product) && (
        <Link href={productGetURI(product)} className="flex-shrink-0 w-24 h-24">
          <div
            className="w-full h-full bg-center bg-cover rounded-3xl"
            style={{
              backgroundImage: productGetBackgroundSrc(product),
            }}
          ></div>
        </Link>
      )}

      <div className={clsx("w-10 ml-2 p-2", "rounded-3xl", "bg-white", "flex flex-col items-center justify-center")}>
        <AddButton onClick={handleAddItem} className="" />
        <span className="my-1.5 font-bold">{quantity}</span>
        <RemoveButton onClick={handleRemoveItem} className="" />
      </div>
    </div>
  );
};

export default observer(CartItemsPantryCard);
