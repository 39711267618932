import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

import { cartGetCount } from "../client";

const CartItemCountTag: FC = () => {
  const count = cartGetCount();

  if (!count) return null;

  return (
    <div
      className={clsx(
        "absolute top-0 right-0",
        "rounded-full",
        "w-[18px] h-[18px]",
        "flex items-center justify-center",
        "bg-[#EE5B5B]",
        "text-[8px] text-white",
        "font-sharp-grotesk-medium",
      )}
    >
      {count}
    </div>
  );
};

export default observer(CartItemCountTag);
