import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import Link from "src/layout/Link";
import LoginButton from "src/modules/auth/components/LoginButton";
import { useStore } from "src/services/store/StoreProvider";

import HeaderCartIcon from "./HeaderCartIcon";

const MyLink: FC<{ href: string; className? }> = ({ href, className, children }) => {
  return (
    <Link
      className={clsx(
        "px-4 py-6",
        "rounded-full",
        "leading-none",
        "font-sharp-grotesk-medium text-charcoal/50 uppercase text-xs",
        "hover:underline hover:text-black",
        className,
      )}
      href={href}
    >
      {children}
    </Link>
  );
};

const HeaderLinks: FC = () => {
  const { isLoggedIn, isAdmin } = useStore();
  const [showAbout, setShowAbout] = useState<boolean>(false);

  const handleShowAbout = () => setShowAbout(!showAbout);

  return (
    <div className="inline-flex items-center justify-end flex-grow">
      {isAdmin && (
        <MyLink href="/admin" className="text-red-500">
          Admin
        </MyLink>
      )}
      <MyLink href="/menu">Menu</MyLink>
      <div
        onClick={handleShowAbout}
        className={clsx(
          "relative",
          "px-3 py-1",
          "rounded-full",
          "cursor-pointer",
          "font-sharp-grotesk-medium text-charcoal/50 uppercase text-xs",
          "hover:underline hover:text-black",
        )}
      >
        About
        {showAbout && (
          <div className={clsx("absolute top-8 left-0", "w-48 p-6", "bg-white", "flex flex-col gap-3")}>
            <MyLink href="/read/about" className="px-0 py-1">
              About Vood
            </MyLink>
            <MyLink href="/read/why-vood" className="px-0 py-1">
              Why Vood
            </MyLink>
            <MyLink href="/read/ethos" className="px-0 py-1">
              Vood Ethos
            </MyLink>
          </div>
        )}
      </div>
      {isLoggedIn && <MyLink href="/profile">Profile</MyLink>}
      <LoginButton />
      <Link href="/start">
        <div className={clsx("btn btn-red", "h-[44px] w-[180px]")}>Get started</div>
      </Link>
      <HeaderCartIcon />
    </div>
  );
};

export default observer(HeaderLinks);
