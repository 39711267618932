import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { cartGroupItems } from "src/modules/cart/client";
import { ProductType } from "src/modules/product/ProductTypeLib";

import CartCheckoutButton from "./CartCheckoutButton";
import CartDiscount from "./CartDiscount";
import CartItemsSection from "./CartItemsSection";
import CartPlanSection from "./CartPlanSection";
import CartTotal from "./CartTotal";

const CartParts: FC<{ productType?: ProductType; showButtons?: boolean }> = ({ productType, showButtons = true }) => {
  const [planItems, otherItems] = cartGroupItems();

  return (
    <>
      <CartPlanSection items={planItems} />
      <CartItemsSection items={otherItems} />
      <CartDiscount />
      <CartTotal />
      <CartCheckoutButton />
    </>
  );
};

export default observer(CartParts);
