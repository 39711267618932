import { useMediaQuery } from "react-responsive";

import { IS_SERVER } from "./SSR";

export const CART_BREAK_POINT = 768;

export const MENU_BREAK_POINT = 768;

// export const useScreenSize = () => {
//   if (IS_SERVER)
//     return { mobile: false, tablet: false, laptop: false, desktop: true };

//   const mobile = useMediaQuery({ query: "(max-width: 767px)" });
//   const tablet = useMediaQuery({
//     query: "(min-width: 768px) and (max-width: 1279px)",
//   });
//   const laptop = useMediaQuery({
//     query: "(min-width: 1280px) and (max-width: 1919px)",
//   });
//   const desktop = useMediaQuery({ query: "(min-width: 1920px)" });
//   return {
//     mobile,
//     tablet,
//     laptop,
//     desktop,
//   };
// };

export const useIsModalMenu = () =>
  IS_SERVER ? false : useMediaQuery({ query: `(max-width: ${MENU_BREAK_POINT}px)` });
