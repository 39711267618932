import React, { FC } from "react";
import TextField, { TextFieldProps } from "src/components/form/TextField";

const AuthField: FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      className="py-3 mb-1 text-black placeholder-black border-b border-black bg-vood autofill-fix"
    />
  );
};

export default AuthField;
