import clsx from "clsx";
import React, { FC } from "react";

import { useBannerMessage } from "./lib";

const BannerSection: FC = () => {
  const { data, isLoading } = useBannerMessage();

  if (isLoading || !data) return null;

  return (
    <div
      className={clsx(
        "fixed", 
        "top-0 left-0",
        "z-40",
        "bg-vood-purple",
        "w-full",
        "min-h-[58px] py-1",
        "font-sharp-grotesk-medium",
        "text-[12px] text-black",
        "text-center",
        "flex items-center justify-center",
      )}
    >
      {data}
    </div>
  );
};

export default BannerSection;
