import { toastError, toastSuccess } from "src/lib/ToastLib";
import { SetterFunction } from "src/types";

export interface Handle$ {
  setThinking?: SetterFunction;
  onError?: (err) => void;
  toast?: boolean;
  successMessage?: string;
  errorMessage?: string;
}

export const handle$ = async (
  func,
  { setThinking, onError, toast = true, successMessage = "Success", errorMessage }: Handle$,
) => {
  try {
    setThinking?.(true);
    await func();
    toast && toastSuccess(successMessage);
  } catch (err) {
    console.error("err", err);
    onError?.(err);
    toast && toastError(errorMessage || "Error " + err.message);
  } finally {
    setThinking?.(false);
  }
};
