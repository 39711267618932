import currency from "currency.js";
import { store } from "src/services/store/store";

import { DiscountData } from "../discount/schema";
import { VARIANT_PRICE_PLAN, VARIANT_SINGLE, VariantType } from "../product/VariantLib";

export const PLAN_MEALS_MAX = 50;
export const PLAN_MEALS_MIN = 2;

export const planSet = (variant: VariantType = VARIANT_SINGLE, quantity: number = 4, discount: DiscountData = null) => {
  store.plan = {
    variant,
    quantity,
    discount,
  };
};

export const planUnset = () => {
  store.plan = null;
  store.emptyCart();
  store.cart.forEach((i) => (i.deliverySlot = 0));
};

export const planGetItemCost = (): currency => {
  return currency(VARIANT_PRICE_PLAN[store?.plan?.variant]);
};

export const planGetTotal = (variant: VariantType = null, quantity: number = null): currency => {
  if (!variant) variant = store?.plan?.variant;
  if (!quantity) quantity = store?.plan?.quantity;

  if (variant) {
    return currency(VARIANT_PRICE_PLAN[variant]).multiply(quantity);
  }

  return currency(0);
};
