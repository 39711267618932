import React, { FC, useState } from "react";

import CreateAccountForm from "./CreateAccountForm";
import GoogleLoginButton from "./GoogleLoginButton";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";

export interface SubAuthFormProps {
  setRequestError?: (error: string) => void;
  setSuccess?: (error: string) => void;
  setTitle?: (error: string) => void;
  setForm?: (form: string) => void;
}

const AuthForm: FC = () => {
  const [option, setOption] = useState<"login" | "reset" | "create">("login");

  return (
    <div>
      <GoogleLoginButton />

      {option === "login" && <LoginForm setOption={setOption} />}
      {option === "reset" && <ResetPasswordForm setOption={setOption} />}
      {option === "create" && <CreateAccountForm setOption={setOption} />}
    </div>
  );
};

export default AuthForm;
