import React, { FC } from "react";

const D: FC<{ className }> = ({ className }) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0239 12.2906C15.0239 13.5088 14.5399 14.6771 13.6785 15.5385C12.8171 16.4 11.6488 16.8839 10.4305 16.8839H7.93246V7.69723H10.4305C11.6488 7.69723 12.8171 8.18117 13.6785 9.04259C14.5399 9.90401 15.0239 11.0723 15.0239 12.2906ZM22.1223 12.2906C22.1186 9.19086 20.8856 6.21917 18.6937 4.02734C16.5019 1.83552 13.5302 0.602495 10.4305 0.598755H0.833984V23.9824H10.4305C13.5305 23.9796 16.5028 22.7468 18.6948 20.5548C20.8868 18.3628 22.1195 15.3906 22.1223 12.2906Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default D;
