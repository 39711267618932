import currency from "currency.js";
import { get, set } from "lodash";
import { nanoid } from "nanoid";
import { store } from "src/services/store/store";

import { deliveryGetCost } from "../delivery/lib";
import { discountAdjustTotals } from "../discount/lib";
import { planGetTotal } from "../plan/lib";
import { productGetPrice } from "../product/lib";
import { ProductId } from "../product/schema";
import { VariantType } from "../product/VariantLib";
import { CartId, CartItem } from "./schema";

export const cartGetCount = () => {
  if (!store) return 0;
  return store.cart.length;
};

export const cartGetQuantity = (productId: ProductId, variant: VariantType) => {
  if (!store) return 0;
  return store.cart.filter((i) => i.productId === productId && i.variant === variant).length;
};

export const cartAddItem = (productId: ProductId, variant: VariantType, deliverySlot: number = 0) => {
  const cartId: CartId = nanoid();
  const cartItem: CartItem = {
    cartId,
    productId,
    variant,
    deliverySlot,
  };
  store.cart = [...store.cart, cartItem];
};

export const cartRemoveProductId = (productId: ProductId, variant: VariantType) => {
  const cartItem = store.cart
    .slice() // don't mutate
    .reverse() // last in first out
    .find((i) => i.productId === productId && i.variant === variant);
  if (cartItem) cartRemoveCartId(cartItem.cartId);
};

export const cartRemoveAllOf = (productId: ProductId) => {
  console.log("Removing", productId);
  store.cart = store.cart.filter((i) => i.productId !== productId);
};

export const cartRemoveCartId = (cartId: CartId) => {
  store.cart = store.cart.filter((i) => i.cartId !== cartId);
};

/**
 * Group
 * - break out the first matching items for the plan section
 * - the rest are in other
 */

export type CartGroupItems = [CartItem[], CartItem[]];

export const cartGroupItems = (): CartGroupItems => {
  if (!store) return [[], []]; // is store initialized
  return cartGroupItemsBy(store.plan, store.cart);
};

export const cartGroupItemsBy = (plan, items): CartGroupItems => {
  // no plan, then everything is `other`
  if (!plan) return [[], items];

  // loop through the cart
  const { quantity, variant } = plan;
  const result: CartGroupItems = [[], []];
  items.forEach((item) => {
    if (item.variant === variant && result[0].length < quantity) {
      result[0].push(item);
    } else {
      result[1].push(item);
    }
  });
  return result;
};

/**
 * Rollup
 * - given a list of items, return them reduced by item, variant
 */

export interface CartRollup {
  [productId: string]: {
    [variantId: string]: number;
  };
}

export const cartRollup = (items: CartItem[]): CartRollup => {
  if (!items) return {};
  const rollup: CartRollup = {};
  items.forEach(({ productId, variant }) => {
    const qty = get(rollup, [productId, variant]);
    set(rollup, [productId, variant], (qty || 0) + 1);
  });
  return rollup;
};

/**
 * Totals
 */

export interface CartGetTotals {
  other: currency;
  plan: currency;
  delivery: currency;
  discount: currency;
  total: currency;
}

export const cartGetTotals = (): CartGetTotals => {
  const totals = {
    plan: currency(0),
    other: currency(0),
    delivery: currency(0),
    discount: currency(0),
    total: currency(0),
  };

  // store or product cache not initialized
  if (!store?.products) {
    return totals;
  }

  // get none-plan items
  const [, otherItems] = cartGroupItems();

  // add price of other items
  totals.other = otherItems.reduce((a, i) => {
    const product = store?.products[i.productId];
    const price = currency(productGetPrice(product, i.variant));
    return a.add(price);
  }, totals.other);

  // Plan total
  totals.plan = planGetTotal();

  // Delivery total
  totals.delivery = deliveryGetCost(!!store?.plan, store.cart.length);

  // determine plan total
  totals.total = totals.plan.add(totals.other).add(totals.delivery).add(totals.discount);
  const discount = store?.plan?.discount || store?.discount;
  return discountAdjustTotals(totals, discount);
};

export const cartGetCheckoutItems = () => {
  return store?.cart?.map(({ productId, variant, deliverySlot }) => {
    return {
      productId,
      variant,
      deliverySlot,
    };
  });
};

export const cartDataForAnalytics = (orderId: string = "") => {
  let quantityCount = 0;
  let cartData = store?.cart?.map(({ productId, variant }) => {
    const product = store?.products[productId];
    let isPlan = false;

    if (store?.plan && store.plan?.variant === variant && quantityCount < store.plan?.quantity) {
      quantityCount++;
      isPlan = true;
    }

    return {
      id: product._id,
      sku: product.handle,
      variant,
      name: product.title,
      price: productGetPrice(product, variant, isPlan),
      category: product.type,
      brand: "Vood",
      quantity: 1,
    };
  });

  //convert the single items to combined quantities
  const products = cartData.reduce((accumulator, item) => {
    let found = accumulator.findIndex((product) => product.id === item.id);
    if (found > -1) {
      accumulator[found].quantity += item.quantity;
    } else {
      accumulator.push(item);
    }

    return accumulator;
  }, []);

  return {
    event: "transaction",
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId, // Transaction ID. Required for purchases and refunds.
          affiliation: "EatVood",
          revenue: cartGetTotals().total.value, // Total transaction value (incl. tax and shipping)
          // tax: "4.90", // @todo needs to be added to cartGetTotals
          shipping: cartGetTotals().delivery.value,
          coupon: store?.plan?.discount?.code ?? "",
          currency: "AUD",
        },
        products: products,
      },
    },
  };
};
